import React, {useEffect, useState} from 'react'
import {Outlet, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import InterviewDetails from './InterviewDetails'
import InterviewMap from './InterviewMap'
import InterviewGraph from './InterviewGraph'

const InterviewData = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const value = searchParams.get('value')
  //   const userId = location.pathname.split('/').pop()
  const {id} = useParams()

  const {currentUser} = useAuth()
  //   const [id, setId] = useState<any>()
  //   useEffect(() => {
  //     setId(userId)
  //   }, [])

  const handleRedirect = (path: string, id: string, value: string, currentUser?: any) => {
    let url = ''
    if (path === 'map') {
      url = `/project/details/${id}/map?value=${value}`
    } else if (path === 'graph' && currentUser?.profileType === 'admin') {
      url = `/project/details/${id}/graph?value=${value}`
    } else if (path === 'report') {
      url = `/project/details/${id}?value=${value}`
    }
    console.log(url)
    navigate(url)
  }
  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <div className='flex gap-4 items-center w-full justify-center'>
          <button
            className='btn btn-primary'
            onClick={() => handleRedirect('report', id, value, currentUser)}
          >
            Report
          </button>
          <button
            className='btn btn-success'
            onClick={() => handleRedirect('map', id, value, currentUser)}
            //     onClick={() => navigate(`/dashboards/details/map/${id}`)}
          >
            Maps
          </button>
          {currentUser?.profileType === 'admin' && (
            <button
              className='btn btn-danger'
              onClick={() => handleRedirect('graph', id, value, currentUser)}
            >
              Graphs
            </button>
          )}
        </div>
      </div>
      <Outlet />
      <Routes>
        <Route index element={<InterviewDetails />} />
        <Route path='/map' element={<InterviewMap />} />
        <Route path='/graph' element={<InterviewGraph />} />
      </Routes>
    </section>
  )
}

export default InterviewData
