import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {getTopProjects} from '../core/_requests'
import {setTopProjects} from '../../../redux/dashboard/dashboardSlice'

const TopProjects = () => {
  const dispatch = useDispatch()
  const {topProjects, topProjectsLoading} = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    const fetchTopProjects = async () => {
      const res = await getTopProjects()
      if (res) {
        dispatch(
          setTopProjects(
            res?.map((project) => ({
              name: project?.name,
              surveyors: project?.agent?.length,
              tasks: project?.tasks?.length,
              surveys: project?.totalSurveys,
            }))
          )
        )
      }
    }

    if (topProjectsLoading) {
      fetchTopProjects()
    }
  }, [])

  return (
    <div className='card border-1 border-gray-300'>
      <div className='card-body'>
        <h5 className='card-title fs-3'>Top Projects (Top 3)</h5>
        <div className='table-responsive'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='min-w-300px'>Project</th>
                <th className='text-center'>Surveyors</th>
                <th className='text-center'>Tasks</th>
                <th className='text-center'>Surveys</th>
              </tr>
            </thead>
            <tbody>
              {topProjects.map((project, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? 'bg-light' : 'bg-white hover:bg-gray-200'}
                >
                  <td className='py-2 px-3'>
                    {project.name?.length > 50
                      ? `${project.name?.substring(0, 50)}...`
                      : project.name}
                  </td>
                  <td className='text-center !text-green-700 py-2'>{project.surveyors}</td>
                  <td className='text-center !text-green-700 py-2'>{project.tasks}</td>
                  <td className='text-center !text-green-700 py-2'>{project.surveys}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TopProjects
