import React, {useEffect, useState} from 'react'
import {getResponseFromInterviewId} from '../_requests'
import * as XLSX from 'xlsx'
import {useAuth} from '../../../modules/auth'
import {Spinner} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'

const ReportPage: React.FC = () => {
  const navigate = useNavigate()
  const [interviewName, setInterviewName] = useState('')
  const [loading, setLoading] = useState(true)
  const [answers, setAnswers] = useState([])
  const [countResponse, setCountResponse] = useState(0)
  const {currentUser} = useAuth()
  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')

    const fetchData = async () => {
      const res = await getResponseFromInterviewId(parts[2])
      console.log(res[0])
      if (res && res[0] && res[0].interviewId) {
        setInterviewName(res[0].interviewId.interviewName)
        setAnswers(res[0].answers.reverse())
        setCountResponse(res[0].answers.length)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const formatDate = (date: Date) => {
    const date1 = new Date(date)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }).format(date1)
    return formattedDate
  }

  const handleDownload = () => {
    const jsonToExcel = (data) => {
      const dataForExcel = data.map((item, index) => ({
        id: index + 1,
        ...item.questions.reduce((acc, curr) => {
          if (curr.type === 'date' && curr.response.length > 0) {
            acc[curr.question] = new Date(curr.response[0]).toDateString()
          } else {
            acc[curr.question] = curr.response.join(', ')
          }
          return acc
        }, {}),
        ...item.geoCoordinates,
      }))

      const worksheet = XLSX.utils.json_to_sheet(dataForExcel)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})

      // Convert Excel data to Blob
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      const fileName = 'data.xlsx'

      // Create download link
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.setAttribute('download', fileName)

      // Trigger download
      downloadLink.click()
    }

    jsonToExcel(answers.reverse())
  }

  const handleEditNavigation = (answerId: string) => {
    navigate(`edit/${answerId}`)
  }

  return (
    <section>
      <div className={`card card-xl-stretch mb-xl-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 capitalize'>{interviewName}</span>
          </h3>
          {currentUser?.profileType === 'admin' && (
            <div className=''>
              <button className='btn btn-primary' onClick={handleDownload}>
                Download Data
              </button>
            </div>
          )}
        </div>
        <div className='card-body py-3'>
          {loading ? (
            <>
              <div className='w-full flex items-center justify-center'>
                <Spinner animation='border' variant='primary' />
              </div>
            </>
          ) : (
            <>
              <h4 className='fw-semibold fs-4'>Total Responses: {countResponse}</h4>
              {answers?.map((item, index) => {
                return (
                  <div className='row gx-10 mb-5 card p-3' key={index}>
                    <div className='col-lg-12'>
                      <div className='text-muted flex justify-between'>
                        <p>#{countResponse - index}</p>
                        <p>{formatDate(item.createdAt)}</p>
                      </div>
                      <div className='font-semibold'>
                        {item.questions.map((elem, idx) => {
                          return (
                            <div className='my-2' key={idx}>
                              <p className=''>
                                {idx + 1}) {elem.question}
                              </p>
                              {elem?.type === 'date' && elem.response?.[0]?.length > 0 ? (
                                <span className='text-primary'>
                                  {new Date(elem.response[0]).toDateString()}
                                </span>
                              ) : (
                                elem.response.map((currElem, i) => {
                                  return (
                                    <span className='text-primary' key={i}>
                                      {currElem}{' '}
                                    </span>
                                  )
                                })
                              )}
                              {elem?.comment !== '' && (
                                <p className='text-muted'>Comment: {elem.comment}</p>
                              )}
                            </div>
                          )
                        })}
                      </div>
                      <div className='text-muted flex justify-between mt-3 align-items-center'>
                        <button
                          type='button'
                          className='btn btn-sm btn-warning'
                          onClick={() => handleEditNavigation(item?._id)}
                        >
                          <KTIcon iconName='pencil' className='fs-5' />
                          Edit
                        </button>
                        <p>
                          lat: {item.geoCoordinates.lat}, lng: {item.geoCoordinates.lng}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default ReportPage
