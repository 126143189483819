import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DASHBOARD_URL = `${API_URL}/dashboard`

export const getDashboardStats = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${DASHBOARD_URL}/stats`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getTopProjects = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${DASHBOARD_URL}/projects`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getTopInterviews = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${DASHBOARD_URL}/topinterviews`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getTopSurveyors = async (): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.get(`${DASHBOARD_URL}/topsurveyors`)
    return response.data
  } catch (error) {
    throw error
  }
}
