import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setTopSurveys} from '../../../redux/dashboard/dashboardSlice'
import {getTopInterviews} from '../core/_requests'

const TopSurveys = () => {
  const dispatch = useDispatch()
  const {topSurveys, topSurveysLoading} = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    const fetchTopSurveys = async () => {
      const res = await getTopInterviews()
      if (res) {
        dispatch(
          setTopSurveys(
            res?.map((survey) => ({
              interviewName: survey?.interview,
              responses: survey?.totalResponses,
            }))
          )
        )
      }
    }

    if (topSurveysLoading) {
      fetchTopSurveys()
    }
  }, [])

  return (
    <div className='col-lg-4'>
      <div className='card border-1 border-gray-300 h-100'>
        <div className='card-body'>
          <h5 className='card-title fs-3'>Top Surveys (Top 10)</h5>
          <div className='p-2 h-100'>
            <table className='table h-100 overflow-hidden'>
              <thead className='border-b border-gray-400'>
                <tr>
                  <th className=''>Survey</th>
                  <th className='text-center'>Responses</th>
                </tr>
              </thead>
              <tbody style={{borderBottom: '5px solid #e0e0e0', borderRadius: '10px'}}>
                {topSurveys.map((survey, index) => (
                  <tr key={index} style={{borderBottom: '5px solid #e0e0e0', borderRadius: '10px'}}>
                    <td className='py-2 px-3'>{survey.interviewName}</td>
                    <td className='text-center !text-green-700 py-2'>{survey.responses}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopSurveys
