import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
// const GET_AGENT_URL = `${API_URL}/agent/actives`
const PROJECT_URL = `${API_URL}/project`
const INTERVIEW_URL = `${API_URL}/interview`
const PARKING_URL = `${API_URL}/parking`
const RESPONSE_URL = `${API_URL}/response`
const PARKING_RESPONSE_URL = `${API_URL}/parkingResponse`
const COORDINATE_URL = `${API_URL}/coordinate`
const GEOFENCING_URL = `${API_URL}/geofencing`
const GEOFENCELOGS_URL = `${API_URL}/geofencinglogs`
const JOURNEYTIME_URL = `${API_URL}/journeytime`

export async function getResponseInterview(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RESPONSE_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseInterviewWithProject(
  searchTerm: string = '',
  startDate: any = '',
  endDate: any = ''
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${RESPONSE_URL}/responsewithproject?search_term=${searchTerm}&start_date=${startDate}&end_date=${endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseParking(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PARKING_RESPONSE_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseFromInterviewId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RESPONSE_URL}/${id}/responses`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
export async function getReportCount(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${API_URL}/report`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseFromParkingId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PARKING_RESPONSE_URL}/${id}/parkingResponses`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getInterview(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INTERVIEW_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getParking(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PARKING_URL}/all`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getProject(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getGeoCoordinates(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RESPONSE_URL}/${id}/geoco-ordinates`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteResponses(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${RESPONSE_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteParkingResponses(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${PARKING_RESPONSE_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getCoordinate(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${COORDINATE_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getCoordinatebyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${COORDINATE_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteCoordinate(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${COORDINATE_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getGeofencing(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GEOFENCING_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getGeofencingbyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GEOFENCING_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getGeofencingbySurveyor(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GEOFENCING_URL}/surveyor/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteGeofencing(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${GEOFENCING_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getGeofenceLogsbyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GEOFENCELOGS_URL}/${id}/geofence`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getJT(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${JOURNEYTIME_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getJTById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${JOURNEYTIME_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function updateProject(data: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${JOURNEYTIME_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteJT(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${JOURNEYTIME_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
