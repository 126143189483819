import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ProjectTable} from './Components/ProjectTable'
import ProjectForm from './Components/ProjectForm'
import ProjectEdit from './Components/ProjectEdit'
import ProjectDetails from './Components/ProjectDetails'
import InterviewDetails from './Components/InterviewDetails'
import InterviewMap from './Components/InterviewMap'
import InterviewData from './Components/InterviewData'

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]

const ProjectPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='table'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Project Table</PageTitle>
              <ProjectTable />
            </>
          }
        />
        <Route
          path='form'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Details</PageTitle>
              <ProjectForm />
            </>
          }
        />
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Edit</PageTitle>
              <ProjectEdit />
            </>
          }
        />
        <Route path='details/:id/*' element={<InterviewData />} />
        {/* <Route path='details/map/:id' element={<InterviewMap />} /> */}

        <Route path='project/:id' element={<ProjectDetails />} />

        <Route index element={<Navigate to='/project/table' />} />
      </Route>
    </Routes>
  )
}

export default ProjectPage
