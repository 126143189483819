import React from 'react'

interface ToggleButtonProps {
  index: number
  handleRequiredButton: () => void
  text?: string
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  index,
  handleRequiredButton,
  text = 'Required',
}) => {
  const handleToggle = () => {
    handleRequiredButton()
  }

  return (
    <div className='flex items-center mt-2'>
      <div className='form-check form-switch form-check-custom form-check-solid'>
        <input
          className='form-check-input h-20px w-30px'
          type='checkbox'
          value=''
          id={`switchCheck-${text}-${index}`}
          onClick={handleToggle}
        />
        <label className='form-check-label' htmlFor={`switchCheck-${text}-${index}`}>
          {text}
        </label>
      </div>
    </div>
  )
}

export default ToggleButton
