import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

const PROJECT_URL = `${API_URL}/project`
const CHECKLIST_URL = `${API_URL}/CheckList`
const CHECKLIST_RESPONSE_URL = `${API_URL}/ChecklistResponse`

export async function getResponseChecklist(startDate: any = '', endDate: any = ''): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${CHECKLIST_RESPONSE_URL}?start_date=${startDate}&end_date=${endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponsesFromChecklistId(id: string): Promise<any> {
  console.log('Checklist ID:', id)
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${CHECKLIST_RESPONSE_URL}/${id}/responses`
    )
    return response.data
  } catch (error) {
    console.error('Get Checklist Responses by Checklist ID error:', error)
    console.error('Response data:', error.response?.data)
    throw error
  }
}

export async function getChecklist(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${CHECKLIST_URL}/all`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getChecklistGeoCoordinates(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${CHECKLIST_RESPONSE_URL}/${id}/geocoordinates`
    )
    return response.data
  } catch (error) {
    console.error('Get Checklist Geo Coordinates error:', error)
    throw error
  }
}

export async function deleteChecklistResponse(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${CHECKLIST_RESPONSE_URL}/${id}`)
    return response.data
  } catch (error) {
    console.error('Delete Checklist Response error:', error)
    throw error
  }
}
