import React, {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  getInterviewbyid,
  getResponseByInterviewAndAnswer,
  editInterviewResponse,
  getCountries,
} from '../_requets'
import {debounce} from 'lodash'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import {RootState} from '../../../redux/store'
import {useSelector, useDispatch} from 'react-redux'
import {setCountries} from '../../../redux/utils/countrySlice'
import Swal from 'sweetalert2'

const EditInterviewQuestionaire: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {countries, loading: countryLoading} = useSelector((state: RootState) => state.country)
  const [validateInput, setValidateInput] = useState(false)
  const [data, setData] = useState([])
  const [interviewName, setInterviewName] = useState('')
  const [projectName, setProjectName] = useState('')
  const [questions, setQuestions] = useState([
    {
      question: '',
      type: '',
      response: [],
      comment: '',
    },
  ])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getInterviewbyid(params.id)
      // console.log(res)
      setInterviewName(res.interviewName)
      setProjectName(res.project.name)
      setData(res.questions)
      // const data = res.questions.map((item) => {
      //   if (item.type !== 'text') {
      //     return {question: item.question, type: item.type, response: []}
      //   }
      //   const updateData = {question: item.question, type: item.type, response: item.options}
      //   return updateData
      // })
      // setQuestions(data)
    }

    const fetchResponses = async () => {
      const res = await getResponseByInterviewAndAnswer(params.id, params.answerId)
      console.log(res)
      setQuestions(res?.answers?.questions)
    }

    if (params.id !== undefined) fetchData()
    if (params.answerId !== undefined) fetchResponses()
  }, [params])

  useEffect(() => {
    const fetchCountries = async () => {
      const res = await getCountries()
      dispatch(setCountries(res))
    }
    if (countryLoading) fetchCountries()
  }, [countryLoading])

  const handleFormEdit = debounce(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let formValid = true
    questions.forEach((item, idx) => {
      if (data[idx].required) {
        if (item.type === 'text' && item.response[0]?.length < 3) {
          formValid = false
        } else if (item.response.length < 1) {
          formValid = false
        }
      }
    })
    if (!formValid) {
      setValidateInput(true)
      return
    }
    const datares = {
      questions: questions.map((item, idx) => {
        if (item.type !== 'checkbox') {
          return item
        } else {
          return {
            ...item,
            response: item.response,
          }
        }
      }),
    }
    console.log(datares)
    const res = await editInterviewResponse(params.id, params.answerId, datares)
    console.log(res)
    if (res) {
      Swal.fire({
        title: 'Success',
        text: 'Response submitted successfully',
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#007bff',
      }).then(() => {
        navigate(`/reports/${params.id}`)
      })
    }
  }, 1000)

  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold capitalize'>{interviewName}</h3>
        <h4 className='mb-4 text-base font-medium capitalize'>{projectName}</h4>
        <div className='w-full rounded-lg bg-white text-base md:mx-4'>
          <form id='kt_modal_add_user_form' className='form'>
            <div className='flex flex-col justify-around gap-4 p-4 text-gray-800'>
              {data.map((element, index) => {
                return (
                  <div key={index}>
                    <p className=''>
                      {element.question}{' '}
                      {element.required && <span className='text-red-600'>*</span>}
                    </p>
                    {element.type === 'text' && (
                      <div>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          placeholder={element.question}
                          required={element.required}
                          value={questions[index].response[0]}
                          onChange={(e) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [e.target.value],
                              }
                              return updatedFormData
                            })
                          }
                        />
                        {element.required &&
                          validateInput &&
                          questions[index].response[0]?.length < 1 && (
                            <div className='alert alert-danger mt-2' role='alert'>
                              Input cannot be less than 3!
                            </div>
                          )}
                      </div>
                    )}
                    {element.type === 'checkbox' && (
                      <div className='ml-2 flex flex-col gap-1'>
                        {element.options.map((currElem, idx) => {
                          return (
                            <label key={idx} className='form-check-label'>
                              <input
                                type='checkbox'
                                className='form-check-input me-3 mb-2'
                                value={currElem}
                                checked={questions[index]?.response?.includes(currElem)}
                                onChange={(e) =>
                                  setQuestions((prev) => {
                                    const updatedFormData = [...prev]
                                    if (e.target.checked) {
                                      updatedFormData[index] = {
                                        ...updatedFormData[index],
                                        response: [
                                          ...updatedFormData[index].response,
                                          e.target.value,
                                        ],
                                      }
                                    } else {
                                      updatedFormData[index] = {
                                        ...updatedFormData[index],
                                        response: updatedFormData[index].response.filter(
                                          (item) => item !== e.target.value
                                        ),
                                      }
                                    }
                                    return updatedFormData
                                  })
                                }
                              />
                              {currElem}
                            </label>
                          )
                        })}
                        {element.required && validateInput && questions[index].response.length < 1 && (
                          <div className='alert alert-danger mt-2' role='alert'>
                            This option is required
                          </div>
                        )}
                      </div>
                    )}
                    {element.type === 'multiple' && (
                      <div className='ml-2 flex flex-col gap-1'>
                        {element.options.map((currElem, idx) => {
                          return (
                            <label key={idx} className='form-check-label'>
                              <input
                                type='radio'
                                name={`radio${index}`}
                                className='form-check-input me-3 mb-2'
                                required={element.required}
                                value={currElem}
                                checked={questions[index]?.response?.[0] === currElem}
                                onChange={(e) =>
                                  setQuestions((prev) => {
                                    const updatedFormData = [...prev]
                                    updatedFormData[index] = {
                                      ...updatedFormData[index],
                                      response: [e.target.value],
                                    }
                                    return updatedFormData
                                  })
                                }
                              />
                              {currElem}
                            </label>
                          )
                        })}
                        {element.required && validateInput && questions[index].response.length < 1 && (
                          <div className='alert alert-danger mt-2' role='alert'>
                            This option is required
                          </div>
                        )}
                      </div>
                    )}
                    {element.type === 'dropdown' && (
                      <div>
                        <select
                          className='form-select form-select-sm form-select-solid mb-2'
                          ata-control='select2'
                          data-placeholder='Latest'
                          data-hide-search='true'
                          required={element.required}
                          defaultValue={'select'}
                          value={questions[index]?.response?.[0]}
                          onChange={(e) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [e.target.value],
                              }
                              return updatedFormData
                            })
                          }
                        >
                          <option value='select' disabled>
                            Select
                          </option>
                          {element.options.map((currElem, idx) => {
                            return (
                              <option key={idx} value={currElem}>
                                {currElem}
                              </option>
                            )
                          })}
                        </select>
                        {element.required && validateInput && questions[index].response.length < 1 && (
                          <div className='alert alert-danger mt-2' role='alert'>
                            This option is required
                          </div>
                        )}
                      </div>
                    )}
                    {element.type === 'number' && (
                      <div className='mb-3'>
                        <input
                          type='number'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          placeholder={element.question}
                          required={element.required}
                          value={questions[index].response[0]}
                          onChange={(e) => {
                            const value = e.target.value
                            const numericValue = parseFloat(value)
                            if (!isNaN(numericValue)) {
                              setQuestions((prev) => {
                                const updatedFormData = [...prev]
                                updatedFormData[index] = {
                                  ...updatedFormData[index],
                                  response: [numericValue],
                                }
                                return updatedFormData
                              })
                            } else {
                              setQuestions((prev) => {
                                const updatedFormData = [...prev]
                                updatedFormData[index] = {
                                  ...updatedFormData[index],
                                  response: [''],
                                }
                                return updatedFormData
                              })
                            }
                          }}
                        />
                        {element.required &&
                          validateInput &&
                          (questions[index].response[0]?.length < 1 ||
                            isNaN(questions[index].response[0])) && (
                            <div className='alert alert-danger mt-2' role='alert'>
                              Input must be a valid number!
                            </div>
                          )}
                      </div>
                    )}
                    {element.type === 'date' && (
                      <div>
                        <Flatpickr
                          className='form-control form-control-solid my-3'
                          placeholder={element.question}
                          required={element.required}
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                          value={
                            questions[index].response ? new Date(questions[index].response[0]) : ''
                          }
                          onChange={(date) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [new Date(date).toISOString()],
                              }
                              return updatedFormData
                            })
                          }
                        />
                      </div>
                    )}
                    {/* country dropdown */}
                    {element.type === 'country' && (
                      <div>
                        <select
                          className='form-select form-select-sm form-select-solid mb-2'
                          required={element.required}
                          defaultValue={'select'}
                          value={questions[index].response[0]}
                          onChange={(e) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [e.target.value],
                              }
                              return updatedFormData
                            })
                          }
                        >
                          <option value='select' disabled>
                            Select Country
                          </option>
                          {countries.map((country, idx) => (
                            <option key={idx} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {element.other && (
                      <div>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          placeholder='other...'
                          onChange={(e) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [e.target.value],
                              }
                              return updatedFormData
                            })
                          }
                        />
                        {element.required &&
                          validateInput &&
                          (questions[index].response.length < 1 ||
                            questions[index].response[0]?.length < 1) && (
                            <div className='alert alert-danger mt-2' role='alert'>
                              Input cannot be less than 3!
                            </div>
                          )}
                      </div>
                    )}
                    <div>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        placeholder='Comment'
                        required={element.comment}
                        value={questions[index].comment}
                        onChange={(e) =>
                          setQuestions((prev) => {
                            const updatedFormData = [...prev]
                            updatedFormData[index] = {
                              ...updatedFormData[index],
                              comment: e.target.value,
                            }
                            return updatedFormData
                          })
                        }
                      />
                      {element.comment && questions[index].comment?.length < 3 && (
                        <div className='alert alert-danger mt-2' role='alert'>
                          Comment is required
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className=''>
              <button
                type='button'
                onClick={handleFormEdit}
                className='btn btn-warning uppercase my-4 mx-4'
              >
                Edit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default EditInterviewQuestionaire
