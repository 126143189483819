import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getChecklistbyid, postResponse} from '../_requets'

const ChecklistQuestionaire: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [validateInput, setValidateInput] = useState(false)
  const [data, setData] = useState([])
  const [checkListName, setCheckListName] = useState('')
  const [projectName, setProjectName] = useState('')
  const [questions, setQuestions] = useState([
    {
      question: '',
      type: '',
      response: [],
    },
  ])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getChecklistbyid(params.id)
      console.log(res)
      setCheckListName(res.CheckListName)
      setProjectName(res.project.name)
      setData(res.questions)
      const data = res.questions.map((item) => {
        if (item.type !== 'text') {
          return {question: item.question, type: item.type, response: []}
        }
        const updateData = {question: item.question, type: item.type, response: item.options}
        return updateData
      })
      setQuestions(data)
    }
    fetchData()
  }, [])

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let formValid = true
    questions.forEach((item, idx) => {
      if (data[idx].required) {
        if (item.type === 'text' && item.response[0]?.length < 3) {
          formValid = false
        } else if (item.response.length < 1) {
          formValid = false
        }
      }
    })
    if (!formValid) {
      setValidateInput(true)
      return
    }
    var lat, lng
    if ('geolocation' in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          // Retrieve latitude and longitude
          lat = position.coords.latitude
          lng = position.coords.longitude

          // You can use the latitude and longitude here
          const data = {
            checklistId: params.id,
            questions: questions.map((item, idx) => {
              if (item.type !== 'checkbox') {
                return item
              } else {
                return {
                  ...item,
                  response: item.response,
                }
              }
            }),
            lat,
            lng,
          }

          const res = await postResponse(data)
          // console.log(data)
          if (res) {
            navigate('/Checklist')
          }
        },
        function (error) {
          // Handle any errors that may occur
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log('User denied the request for Geolocation.')
              break
            case error.POSITION_UNAVAILABLE:
              console.log('Location information is unavailable.')
              break
            case error.TIMEOUT:
              console.log('The request to get user location timed out.')
              break
          }
        }
      )
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }

  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold capitalize'>{checkListName}</h3>
        <h4 className='mb-4 text-base font-medium capitalize'>{projectName}</h4>
        <div className='w-full rounded-lg card text-base md:mx-4'>
          <form id='kt_modal_add_user_form' className='form' noValidate onSubmit={handleFormSubmit}>
            <div className='flex flex-col justify-around gap-4 p-4 text-gray-800'>
              {data.map((element, index) => {
                return (
                  <div key={index}>
                    <p className=''>
                      {index + 1}. {element.question}{' '}
                      {element.required && <span className='text-red-600'>*</span>}
                    </p>
                    {element.type === 'text' && (
                      <div>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          placeholder={element.question}
                          required={element.required}
                          value={questions[index].response[0]}
                          onChange={(e) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [e.target.value],
                              }
                              return updatedFormData
                            })
                          }
                        />
                        {element.required &&
                          validateInput &&
                          questions[index].response[0]?.length < 1 && (
                            <div className='alert alert-danger mt-2' role='alert'>
                              Input cannot be less than 3!
                            </div>
                          )}
                      </div>
                    )}
                    {element.type === 'checkbox' && (
                      <div className='ml-2 flex flex-col gap-1'>
                        {element.options.map((currElem, idx) => {
                          return (
                            <label key={idx} className='form-check-label'>
                              <input
                                type='checkbox'
                                className='form-check-input me-3 mb-2'
                                value={currElem}
                                checked={questions[index].response.includes(currElem)}
                                onChange={(e) =>
                                  setQuestions((prev) => {
                                    const updatedFormData = [...prev]
                                    if (e.target.checked) {
                                      updatedFormData[index] = {
                                        ...updatedFormData[index],
                                        response: [
                                          ...updatedFormData[index].response,
                                          e.target.value,
                                        ],
                                      }
                                    } else {
                                      updatedFormData[index] = {
                                        ...updatedFormData[index],
                                        response: updatedFormData[index].response.filter(
                                          (item) => item !== e.target.value
                                        ),
                                      }
                                    }
                                    return updatedFormData
                                  })
                                }
                              />
                              {currElem}
                            </label>
                          )
                        })}
                        {element.required && validateInput && questions[index].response.length < 1 && (
                          <div className='alert alert-danger mt-2' role='alert'>
                            This option is required
                          </div>
                        )}
                      </div>
                    )}
                    {element.type === 'multiple' && (
                      <div className='ml-2 flex flex-col gap-1'>
                        {element.options.map((currElem, idx) => {
                          return (
                            <label key={idx} className='form-check-label'>
                              <input
                                type='radio'
                                name={`radio${index}`}
                                className='form-check-input me-3 mb-2'
                                required={element.required}
                                value={currElem}
                                onChange={(e) =>
                                  setQuestions((prev) => {
                                    const updatedFormData = [...prev]
                                    updatedFormData[index] = {
                                      ...updatedFormData[index],
                                      response: [e.target.value],
                                    }
                                    return updatedFormData
                                  })
                                }
                              />
                              {currElem}
                            </label>
                          )
                        })}
                        {element.required && validateInput && questions[index].response.length < 1 && (
                          <div className='alert alert-danger mt-2' role='alert'>
                            This option is required
                          </div>
                        )}
                      </div>
                    )}
                    {element.type === 'dropdown' && (
                      <div>
                        <select
                          className='form-select form-select-sm form-select-solid mb-2'
                          ata-control='select2'
                          data-placeholder='Latest'
                          data-hide-search='true'
                          required={element.required}
                          onChange={(e) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [e.target.value],
                              }
                              return updatedFormData
                            })
                          }
                        >
                          <option value='select' disabled>
                            Select
                          </option>
                          {element.options.map((currElem, idx) => {
                            return (
                              <option key={idx} value={currElem}>
                                {currElem}
                              </option>
                            )
                          })}
                        </select>
                        {element.required && validateInput && questions[index].response.length < 1 && (
                          <div className='alert alert-danger mt-2' role='alert'>
                            This option is required
                          </div>
                        )}
                      </div>
                    )}
                    {element.other && (
                      <div>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          placeholder='other...'
                          onChange={(e) =>
                            setQuestions((prev) => {
                              const updatedFormData = [...prev]
                              updatedFormData[index] = {
                                ...updatedFormData[index],
                                response: [e.target.value],
                              }
                              return updatedFormData
                            })
                          }
                        />{' '}
                        {element.required &&
                          validateInput &&
                          (questions[index].response.length < 1 ||
                            questions[index].response[0]?.length < 1) && (
                            <div className='alert alert-danger mt-2' role='alert'>
                              Input cannot be less than 3!
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className=''>
              <button type='submit' className='btn btn-primary upparcase my-4 mx-4'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ChecklistQuestionaire
