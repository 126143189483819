/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import logo from './components/odc-logo.png'
const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a
              href='https://odcme.ae/privacy-policy/'
              className='px-5'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>

            {/* <a href='#' className='px-5' target='_blank'>
              Plans
            </a> */}

            <a
              href='https://odcme.ae/contact-us/'
              className='px-5'
              target='_blank'
              rel='noreferrer'
            >
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg-2.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column py-15 px-5 px-md-15 w-100 mt-5 '>
          {/* begin::Logo */}
          <Link to='/' className='ml-auto mr-auto sm:ml-auto sm:mr-0'>
            <img alt='Logo' src={logo} className='h-100px' />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          {/* <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          /> */}
          {/* end::Image */}

          <h1 className='text-white text-[8.5rem] font-bold mt-20 hidden sm:block'>
            ODC
            <br />
            Survey
          </h1>

          <p className='text-white text-7xl font-semibold hidden sm:block'>Application</p>

          <p className='text-white text-2xl font-normal hidden sm:block'>
            Revolutionizing Traffic Survey Solutions
            <br />
            <br />
            <span>
              Traffic surveys are crucial for urban planning,
              <br />
              infrastructure development, and traffic management.
              <br />
              ODC Survey App, a cutting-edge software designed to
              <br />
              redefine how traffic surveys are conducted and analyzed.
            </span>
          </p>

          {/* <img
            className='ml-auto w-[65%] h-[50%] aspect-video'
            src={toAbsoluteUrl('/media/misc/progress-completed.png')}
            alt=''
          /> */}

          {/* begin::Title */}
          {/* <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1> */}
          {/* end::Title */}

          {/* begin::Text */}
          {/* <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the interview.
          </div> */}
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
