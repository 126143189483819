/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
import axios, { AxiosResponse } from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const RESPONSE_URL = `${API_URL}/agent/search`

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [tasks, setTasks] = useState<string[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response:AxiosResponse<any> = await axios.get(`${RESPONSE_URL}/tasks/${currentUser?._id}`)
      setTasks(response.data)
    }
    fetchData()
  },[])


  return (
    <>
    {
      currentUser?.profileType === 'admin' && (
        <SidebarMenuItem to='/dashboards' icon='switch' title='Dashboard' fontIcon='bi-layers' />
      )
    }
    <SidebarMenuItem to='/project' icon='switch' title='Projects' fontIcon='bi-layers' />
    
{ currentUser?.profileType === 'admin' && <SidebarMenuItem to='/reports' icon='switch' title='Reports' fontIcon='bi-layers' />
}

    <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tasks</span>
          </div>
      </div>
   
    {(currentUser?.profileType === 'admin' || tasks.includes("interview")) &&<SidebarMenuItem to='/interview' icon='switch' title='Interview' fontIcon='bi-layers' />}
    {(currentUser?.profileType === 'admin' || tasks.includes("checklist")) && <SidebarMenuItem to='/checklist' icon='switch' title='Checklist' fontIcon='bi-layers' />}
    {(currentUser?.profileType === 'admin' || tasks.includes('parking')) && <SidebarMenuItem to='/parking' icon='switch' title='Parking Occupancy' fontIcon='bi-layers' />}
    {(currentUser?.profileType === 'admin' || tasks.includes('geofencing')) && <SidebarMenuItem to='/geofencing' icon='switch' title='Geo Fencing' fontIcon='bi-layers' />}
    {(currentUser?.profileType === 'admin' || tasks.includes('journeytime')) && <SidebarMenuItem to='/journeytime' icon='switch' title='Journey Time' fontIcon='bi-layers' />}
    {(currentUser?.profileType === 'admin' || tasks.includes('coordinate')) && <SidebarMenuItem to='/coordinate' icon='switch' title='Co-ordinate' fontIcon='bi-layers' />}



        {currentUser?.profileType === 'admin' && (
        <>
        <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      

        <SidebarMenuItem to='/user-management/users' icon='abstract-28' title='User management' fontIcon='bi-layers'/>
        </>)}
   
        

  
   

    

  
    {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      <SidebarMenuItem to='/user-management/users' icon='abstract-28' title='User management' fontIcon='bi-layers'/>
 
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
