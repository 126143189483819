import {Link, useNavigate} from 'react-router-dom'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {deleteChecklist, getChecklist, getChecklistbyId, getProject, postDuplicateChecklist} from '../_requets'
import {Spinner} from 'react-bootstrap'
import { useAuth } from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'
import { setChecklist } from '../../../redux/checklist/checklistSlice'
import { setProjects } from '../../../redux/project/projectSlice'

const ChecklistPage = () => {
  const [deleteid, setdeleteid] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const checklist = useSelector((state: RootState) => state.checkList.checklistData)
  const projectList = useSelector((state: RootState) => state.project.projects)
  const {currentUser} = useAuth()
  const handleNewChecklistRedirect = () => {
    navigate('/checklist/create-checklist')
  }

  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    if (currentUser?.profileType === 'admin') {
      if (checklist.length === 0) {
        const res = await getChecklist()
        setTableData(res)
        setLoading(false)
        dispatch(setChecklist(res))
      } else {
        setLoading(false)
        setTableData(checklist)
      }
    } else {
      const res = await getChecklistbyId(currentUser?._id)
      const data = res.map((item: any) => {
        return {
          ...item,
          project: item.project._id
        }
      })
      setTableData(data)
      console.log(res)
    }
    // console.log('res', res)
    if (projectList.length === 0) {
      const res2 = await getProject()
      dispatch(setProjects(res2))
      console.log(res2)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const handleDuplicate = async (id: string) => {
    setLoading(true)
    await postDuplicateChecklist(id)
    fetchData()
    setLoading(false)
  }

  const handleEdit = (id: string) => {
    navigate(`/checklist/edit-Checklist/${id}`)
  }

  const handleDelete = async () => {
    const res = await deleteChecklist(deleteid)
    fetchData()
    if (res !== null) {
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold'>CheckList</h3>
        <div className='w-full rounded-lg card md:mx-4'>
          <div className='flex items-center justify-between p-4'>
            <h3 className='text-lg font-semibold'>CheckList</h3>
            {currentUser?.profileType === 'admin' && <button onClick={handleNewChecklistRedirect} className='btn btn-success btn-sm'>
              <KTIcon iconName='plus' className='fs-3' />
              <span className='text-sm md:text-base'>New CheckList</span>
            </button>}
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {loading ? (
                <>
                  <div className='w-full flex items-center justify-center'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                </>
              ) : (
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='w-6 ps-4 rounded-start'>No.</th>
                      <th className='min-w-[300px]'>Name</th>
                      <th className='min-w-[200px]'>Project</th>
                      <th className='min-w-[150px]'>Surveyors</th>
                      <th className='min-w-[125px]'>Created On</th>
{currentUser?.profileType === 'admin' && <th className='min-w-[100px] rouunded-end'>Modify</th>
}                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className='ps-4 font-semibold'>{index + 1}</td>
                          <td className='px-2 font-semibold capitalize'>
                            <Link to={`/Checklist/${data._id}`}>{data.CheckListName}</Link>
                          </td>
                          <td className='px-2 text-blue-400 capitalize'>
                            {
                              projectList.filter((item, i) => {
                                return item._id === data.project
                              })[0]?.name
                            }
                          </td>
                          <td className='px-2 font-semibold'>
                            {data.surveyors.map((item) => (
                              <span className='badge badge-light-success fs-7 fw-semibold me-2 capitalize'>
                                {item}
                              </span>
                            ))}
                          </td>
                          <td className='px-2 font-semibold'>{formatCreatedAt(data.createdAt)}</td>
                          {currentUser?.profileType === 'admin' && <td className='text-end'>
                            <div className='flex items-center justify-around gap-2 text-lg text-gray-700'>
                              <div
                                className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                                onClick={() => handleDuplicate(data._id)}
                              >
                                <KTIcon iconName='plus' className='fs-3' />
                              </div>
                              <div
                                className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                                onClick={() => handleEdit(data._id)}
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </div>
                              <div
                                className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_2'
                                onClick={() => setdeleteid(data._id)}
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </div>
                            </div>
                          </td>}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h3> Are you sure you want to delete</h3>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChecklistPage
