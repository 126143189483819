import React, {useEffect, useState} from 'react'
import {debounce} from 'lodash'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import {KTIcon} from '../../_metronic/helpers'

interface FilterProps {
  searchTerm: string
  setSearchTerm: (term: string) => void
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  endDate: Date | null
  setEndDate: (date: Date | null) => void
  resetFilter: () => void
}

const Filter: React.FC<FilterProps> = ({
  searchTerm,
  setSearchTerm,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  resetFilter,
}) => {
  const [inputValue, setInputValue] = useState(searchTerm)

  const debouncedSetSearchTerm = debounce((term: string) => {
    setSearchTerm(term)
  }, 300)

  useEffect(() => {
    debouncedSetSearchTerm(inputValue)
    return () => {
      debouncedSetSearchTerm.cancel()
    }
  }, [inputValue])

  const handleReset = () => {
    setInputValue('')
    resetFilter()
  }

  return (
    <div className='flex items-center gap-4'>
      <div className='search-input w-full md:w-auto'>
        <input
          type='text'
          placeholder='Search...'
          className='form-control w-full'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      <div className='date-range w-full md:w-auto'>
        <Flatpickr
          options={{
            mode: 'range',
          }}
          value={[startDate, endDate]}
          dateFormat='d-m-Y'
          className='form-control form-control-solid w-full md:w-250px mx-2 my-4'
          onChange={([start, end]) => {
            setStartDate(start)
            setEndDate(end)
          }}
          placeholder='Pick date'
        />
      </div>
      {/* reset button */}
      <button
        type='button'
        className='btn btn-light-primary btn-sm md:w-auto active:!bg-gray-400'
        onClick={handleReset}
      >
        <span className='md:hidden'>
          <KTIcon iconName='cross' className='fs-3' />
        </span>
        <span className='hidden md:inline'>RESET</span>
      </button>
    </div>
  )
}

export default Filter
