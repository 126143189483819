import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {getProject, postCoordinate} from '../_requests'
import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api'

const CoordinateForm = () => {
  const navigate = useNavigate()
  const [projectList, setProjectList] = useState([])
  const [coordinateData, setCoordinateData] = useState({
    coordinateName: '',
    project: '',
  })
  const [center, setCenter] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  const [coordinates, setCoordinates] = useState([])
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [markerLabel, setMarkerLabel] = useState([])

  useEffect(() => {
    const getlist = async () => {
      const res = await getProject()
      setProjectList(res)
    }
    getlist()

    var lat, lng
    if ('geolocation' in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(async function (position) {
        // Retrieve latitude and longitude
        lat = position.coords.latitude
        lng = position.coords.longitude
        setCenter({lat, lng})
      })
    }
  }, [])

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  const handleMapClick = (event) => {
    setCoordinates((prev) => {
      return [
        ...prev,
        {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
      ]
    })
    setMarkerLabel((prev) => {
      return [...prev, '']
    })
  }

  const onMarkerDelete = (index) => {
    setCoordinates((prev) => {
      return prev.filter((item, ind) => ind !== index)
    })
    setMarkerLabel((prev) => {
      return prev.filter((item, ind) => ind !== index)
    })
  }

  const handleAddLiveLocation = () => {
    var lat, lng
    if ('geolocation' in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(async function (position) {
        // Retrieve latitude and longitude
        lat = position.coords.latitude
        lng = position.coords.longitude
        setCoordinates((prev) => {
          return [
            ...prev,
            {
              lat: lat,
              lng: lng,
            },
          ]
        })
        setMarkerLabel((prev) => {
          return [...prev, '']
        })
      })
    }
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const data = {
      ...coordinateData,
      coordinates: coordinates.map((item, index) => {
        return {
          lat: item.lat,
          lng: item.lng,
          label: markerLabel[index] === '' ? `C${index + 1}` : markerLabel[index],
        }
      }),
    }
    const res = await postCoordinate(data)
    if (res) {
      navigate('/coordinate')
    }
  }

  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold'>Create New Coordinate</h3>
        <div className='w-full rounded-lg card p-4'>
          <form id='kt_modal_add_user_form' className='form' onSubmit={handleFormSubmit}>
            <h3 className='mb-6 text-base font-semibold'>Coordinate Name</h3>
            <input
              type='text'
              name='question'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              required
              placeholder='Coordinate Name'
              value={coordinateData.coordinateName}
              onChange={(e) => {
                setCoordinateData((prev) => ({...prev, coordinateName: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Project Name</h3>
            {projectList.map((data, index) => {
              return (
                <div className='d-flex fv-row my-1' key={data._id}>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      type='radio'
                      name='project_name'
                      value={data._id}
                      id={`projectCheck${data._id}`}
                      className='form-check-input me-3'
                      required
                      onChange={() => {
                        setCoordinateData((prev) => ({...prev, project: data._id}))
                      }}
                    />
                    <label htmlFor={`projectCheck${data._id}`} className='form-check-label'>
                      {data.name}
                    </label>
                  </div>
                </div>
              )
            })}

            <div className='my-3'>
              <button
                type='button'
                className='btn btn-sm btn-success'
                onClick={handleAddLiveLocation}
              >
                Add Current Location
              </button>
            </div>

            <div className='card-body py-4'>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={14}
                onClick={(e) => {
                  handleMapClick(e)
                  setSelectedMarker(null)
                }}
              >
                {/* You can add markers, polylines, etc. here if needed */}
                {coordinates.map((item, index) => {
                  return (
                    <Marker
                      position={item}
                      key={index}
                      onClick={() => setSelectedMarker(index)}
                      label={markerLabel[index]}
                    >
                      {selectedMarker === index && (
                        <InfoWindow onCloseClick={() => setSelectedMarker(null)} position={item}>
                          <div>
                            <h3>Marker {index + 1}</h3>
                            <input
                              type='text'
                              value={markerLabel[index]}
                              placeholder='Add label'
                              className='form-control form-control-solid mb-3 mb-lg-0'
                              onChange={(e) => {
                                setMarkerLabel((prev) => {
                                  const newMarkerLabel = [...prev]
                                  newMarkerLabel[index] = e.target.value
                                  return newMarkerLabel
                                })
                              }}
                            />
                            <p>Lat: {item.lat}</p>
                            <p>Lng: {item.lng}</p>
                            <button
                              onClick={() => onMarkerDelete(index)}
                              className='btn btn-sm btn-danger'
                            >
                              Delete
                            </button>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  )
                })}
              </GoogleMap>
            </div>

            <div className='mx-auto my-6 md:ml-8'>
              <button className='btn btn-primary' type='submit'>
                Create Coordinate
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default CoordinateForm
