import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
// const GET_AGENT_URL = `${API_URL}/agent/actives`
const PROJECT_URL = `${API_URL}/project`
const INTERVIEW_URL = `${API_URL}/interview`
const RESPONSE_URL = `${API_URL}/response`

// export async function getAgent(): Promise<any> {
//   try {
//     const response: AxiosResponse<any> = await axios.get(`${GET_AGENT_URL}`)
//     return response.data
//   } catch (error) {
//     // Handle errors here
//     console.error('Add Client error:', error)
//     throw error
//   }
// }

export async function getProject(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postInterview(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${INTERVIEW_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postDuplicateInterview(id: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${INTERVIEW_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getInterview(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INTERVIEW_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getInterviewWithProject(
  searchTerm: string = '',
  startDate: any = '',
  endDate: any = ''
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${INTERVIEW_URL}/projectwithin?search_term=${searchTerm}&start_date=${startDate}&end_date=${endDate}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getInterviewById(id: String): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INTERVIEW_URL}/get/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getInterviewbyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INTERVIEW_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function updateInterview(data: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${INTERVIEW_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteInterview(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${INTERVIEW_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postResponse(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `${RESPONSE_URL}/${data.interviewId}/response`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseByInterviewAndAnswer(
  interviewId: string,
  answerId: string
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${RESPONSE_URL}/answer/${interviewId}/${answerId}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Get response error:', error)
    throw error
  }
}

export async function editInterviewResponse(
  interviewId: string,
  answerId: string,
  data: any
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${RESPONSE_URL}/${interviewId}/response/${answerId}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Get response error:', error)
    throw error
  }
}

export async function getCountries(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get('https://restcountries.com/v3.1/all')
    return response.data
  } catch (error) {
    console.error('Get countries error:', error)
    throw error
  }
}
