import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  countries: [],
  loading: true,
}

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload
      state.loading = false
    },
  },
})

export const {setCountries} = countrySlice.actions
export default countrySlice.reducer
