import React from 'react'

interface StatsCardProps {
  title: string
  value: string | number
  valueColor: string
}

const StatsCard = ({title, value, valueColor}: StatsCardProps) => {
  return (
    <div className='col-lg-3'>
      <div className='card border-1 border-gray-300'>
        <div className='card-body text-center'>
          <h5 className='card-title text-dark fs-2'>{title}</h5>
          <p className={`card-text fs-2x fw-bold ${valueColor}`}>{value}</p>
        </div>
      </div>
    </div>
  )
}

export default StatsCard
