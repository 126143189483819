import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getTopSurveyors} from '../core/_requests'
import {setTopSurveyors} from '../../../redux/dashboard/dashboardSlice'
import {RootState} from '../../../redux/store'
import Chart from 'react-apexcharts'

const TopSurveyors = () => {
  const dispatch = useDispatch()
  const {topSurveyors, topSurveyorsLoading} = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    const fetchTopSurveyors = async () => {
      const res = await getTopSurveyors()
      if (res) {
        dispatch(
          setTopSurveyors(
            res?.map((surveyor) => ({
              surveyorName: surveyor?._id,
              responses: surveyor?.totalResponses,
            }))
          )
        )
      }
    }

    if (topSurveyorsLoading) {
      fetchTopSurveyors()
    }
  }, [])

  const chartData = {
    options: {
      chart: {
        id: 'top-surveyors-chart',
        toolbar: {
          show: false,
        },
      },
      colors: ['#920000'],
      grid: {
        borderColor: '#e0e0e0',
      },
      plotOptions: {
        bar: {
          borderRadius: 10, // This will make the bar borders circular
        },
      },
      xaxis: {
        categories: topSurveyors.map(
          (surveyor) =>
            surveyor.surveyorName.charAt(0).toUpperCase() + surveyor.surveyorName.slice(1)
        ),
        labels: {
          style: {
            colors: '#333',
            fontSize: '12px',
          },
        },
        title: {
          text: 'Surveyors',
          style: {
            color: '#333',
            fontSize: '14px',
          },
        },
      },
      yaxis: {
        title: {
          text: 'Surveys',
          style: {
            color: '#333',
            fontSize: '14px',
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#fff'],
        },
      },
      tooltip: {
        theme: 'dark',
      },
    },
    series: [
      {
        name: 'Responses',
        data: topSurveyors.map((surveyor) => surveyor.responses),
      },
    ],
  }

  return (
    <div className='card border-1 border-gray-300 mt-5'>
      <div className='card-body'>
        <h5 className='card-title fs-3'>Surveyor Performance (No of Surveys)</h5>
        {/* bar chart */}
        {topSurveyors.length > 0 && (
          <Chart options={chartData.options} series={chartData.series} type='bar' height={350} />
        )}
      </div>
    </div>
  )
}

export default TopSurveyors
