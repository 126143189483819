import React, {useEffect} from 'react'
import StatsCard from './components/StatsCard'
import {useSelector, useDispatch} from 'react-redux'
import {setStats} from '../../redux/dashboard/dashboardSlice'
import {RootState} from '../../redux/store'
import {getDashboardStats} from './core/_requests'
import TopProjects from './components/TopProjects'
import TopSurveys from './components/TopSurveys'
import TopSurveyors from './components/TopSurveyors'

const DashboardWrapper = () => {
  const dispatch = useDispatch()
  const {stats, statsLoading} = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    const fetchStats = async () => {
      const res = await getDashboardStats()
      if (res) {
        dispatch(
          setStats({
            projects: res?.totalProjects || 0,
            surveyors: res?.totalSurveyors || 0,
            surveys: res?.totalSurveys || 0,
            reports: res?.totalReports || 0,
          })
        )
      }
    }

    if (statsLoading) {
      fetchStats()
    }
  }, [])

  return (
    <div className='container'>
      <div className='row gx-10'>
        <StatsCard title='Total Projects' value={stats.projects} valueColor='text-dark' />
        <StatsCard title='Total Surveys' value={stats.surveys} valueColor='text-green-700' />
        <StatsCard title='Total Reports' value={stats.reports} valueColor='text-yellow-600' />
        <StatsCard title='Total Surveyors' value={stats.surveyors} valueColor='text-red-800' />
      </div>
      <div className='row gx-10 mt-10'>
        <div className='col-lg-8'>
          <TopProjects />
          <TopSurveyors />
        </div>
        <TopSurveys />
      </div>
    </div>
  )
}

export default DashboardWrapper
