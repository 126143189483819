import {createSlice} from '@reduxjs/toolkit'

interface TopProjects {
  name: string
  tasks: number
  surveys: number
  surveyors: number
}

interface TopSurveys {
  interviewName: string
  responses: number
}

interface TopSurveyors {
  surveyorName: string
  responses: number
}

const initialSlice = {
  stats: {
    projects: 'Loading...',
    surveys: 'Loading...',
    reports: 'Loading...',
    surveyors: 'Loading...',
  },
  statsLoading: true,
  topProjects: Array<TopProjects>() || [],
  topProjectsLoading: true,
  topSurveys: Array<TopSurveys>() || [],
  topSurveysLoading: true,
  topSurveyors: Array<TopSurveyors>() || [],
  topSurveyorsLoading: true,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialSlice,
  reducers: {
    setStats(state, action) {
      state.stats = action.payload
      state.statsLoading = false
    },
    setTopProjects(state, action) {
      state.topProjects = action.payload
      state.topProjectsLoading = false
    },
    setTopSurveys(state, action) {
      state.topSurveys = action.payload
      state.topSurveysLoading = false
    },
    setTopSurveyors(state, action) {
      state.topSurveyors = action.payload
      state.topSurveyorsLoading = false
    },
  },
})

export const {setStats, setTopProjects, setTopSurveys, setTopSurveyors} = dashboardSlice.actions
export default dashboardSlice.reducer
